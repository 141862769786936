<template>
  <main>
    <div class="content_wrapper">
      <div class="title-bar"><span>Offre pneumatiques hankook</span></div>
      <div class="banniere">
        <img class="desktop" src="../assets/images/ban.jpg" />
        <img class="mobile" src="../assets/images/ban-mobile.jpg" />
        <p>
          Voir réglement de l'opération poids lourd sur
          <a
            href="http://hankooktire.com/fr/files/reglement2021.pdf"
            target="_blank"
            >http://hankooktire.com/fr/files/reglement2021.pdf</a
          >
        </p>
      </div>
      <div class="done">

        <br />
        <h2>Votre participation a bien été enregistrée.</h2>

        <br />
        <p>
          Vous recevrez dans quelques instants un email de confirmation
          d'inscription. <br /><br />
          Si votre participation est conforme aux modalités de l'offre, vous
          recevrez votre remboursement par virement bancaire sous 8 semaines à
          compter de la date de réception de la demande complète et conforme.
          <br /><br />
          Toute participation incomplète, erronée, illisible, transmise hors
          délai, manifestement frauduleuse ou ne respectant pas les modalités
          sera considérée comme nulle.
          <br /><br />
          Aucune copie ou reproduction ne sera acceptée ni prise en compte.<br />
          Toute reproduction frauduleuse expose le participant à des sanctions,
          notamment pénales. <br /><br />
          À bientôt avec Hankook       
          </p> 
        </div>
      <div class="title-bar-left"><span>Vos coordonnées</span></div>
      <div class="recap_wrapper">
      <div>
        <div>Raison sociale : </div><div class="recap_value">{{ result.company_name }}</div>
        <div>Email : </div><div class="recap_value">{{ result.email }}</div>         
        <div>Téléphone : </div><div class="recap_value">{{ result.phone }}</div>                                  
      </div>
      <div>
        <div>Civilité : </div><div class="recap_value">{{result.gender == 'madame'?'Mme':'M'}} {{result.first_name}} {{result.last_name}}</div>
        <div>Adresse : </div><div class="recap_value">{{ result.address.address }}, {{ result.address.postcode }} {{ result.address.city }}</div>
        <div style="color:white;">&nbsp;..</div><div class="recap_value">&nbsp;</div>
      </div>
      </div>
      <div class="title-bar-left"><span>Vos achats</span></div>
        <div class="recap_wrapper">
          <div>
            <div>Date d'achat</div>
            <div class="recap_value">{{buying_date}}</div>
          </div>
          <div>
            <div>Enseigne</div>
            <div class="recap_value">{{result.enseigne}}</div>
          </div>
          <div>
            <div>Nombre de pneumatiques achetés</div>
            <div class="recap_value">{{result.number_value}}</div>
          </div>
        </div>
      <div v-for="(achat, index) in result.prix_produits" :key="index" class="recap_wrapper recap_achats">
      <div>
        <div>Profil : </div><div class="recap_value">{{ achat.produit }}</div>
        <div>Quantité : </div><div class="recap_value">{{achat.quantity}}</div>                             
      </div>
      <div>
        <div>Montant estimé du <br>remboursement :</div>
        <div class="recap_value">{{(achat.quantity*refs[achat.produit]).toFixed(2)}}€ HT ({{achat.quantity}} x {{refs[achat.produit]}}€)</div>   
      </div>
      </div>
      <br>
      <div>
        <div>Montant total estimé du remboursement :</div>
        <div class="recap_value">{{total.toFixed(2)}}€</div>   
      </div>
      <div class="title-bar-left"><span>Vos justificatif</span></div>
      <div class="recap_files">
        <div v-for="file in result.multiple_files" :key="file">
        <a :href="`${file}`" target="_blank"><img src="/icone-pdf.png"></a>
        </div>
  
      </div>
      <div class="endtab"><b>IBAN :</b> {{ result.iban }}</div>
      <div class="endtab"><b>BIC :</b> {{ result.bic }}</div>

      <br />
      <br />
              <router-link to="/" tag="button" class="participer">Quitter</router-link>
    </div>
  </main>
</template>
<script>
var dayjs = require('dayjs')

export default {
  name: "win",
  data: function() {
    return {
      result: window.result,
      refs: {
        "10R22.5 141/139M AH33": 20,
        "11R22.5 148/145J AU04": 20,
        "11R22.5 148/145L AH31": 20,
        "11R22.5 148/145L AH33": 20,
        "11R22.5 148/145L TH22": 20,
        "11R22.5 148/145L TH22": 20,
        "11R22.5 148/145L TH31": 20,
        "12R22.5 152/148L AH22": 20,
        "12R22.5 152/149L AH31": 20,
        "12R22.5 152/149L DH33+": 20,
        "13R22.5 156/150L AH33": 20,
        "205/65R17.5 129/127 J TH22": 10,
        "205/75R17.5 124/122M AH35": 10,
        "205/75R17.5 124/122M DH35": 10,
        "215/75R17.5 126/124M AH35": 10,
        "215/75R17.5 126/124M DH35": 10,
        "215/75R17.5 128/126M AH35": 10,
        "215/75R17.5 135/133J TH22": 10,
        "215/75R17.5 135/133J TH22": 10,
        "215/75R17.5 135/133J TL10+": 10,
        "225/75R17.5 129/127M AH35": 10,
        "225/75R17.5 129/127M DH35": 10,
        "235/75R17.5 132/130M AH35": 10,
        "235/75R17.5 132/130M DH35": 10,
        "235/75R17.5 132/130M DH35": 10,
        "235/75R17.5 143/141J TH22": 10,
        "235/75R17.5 143/141J TL10+": 10,
        "245/70R17.5 136/134M AH35": 10,
        "245/70R17.5 136/134M DH35": 10,
        "245/70R17.5 143/141J TH22": 10,
        "245/70R17.5 143/141J TL10+": 10,
        "245/70R19.5 133/132M AH35": 15,
        "245/70R19.5 136/134L AU03": 15,
        "245/70R19.5 136/134M AH35": 15,
        "245/70R19.5 136/134M DH35": 15,
        "245/70R19.5 136/134M DH35": 15,
        "245/70R19.5 141/140J TH22": 15,
        "245/70R19.5 141/140J TH22": 15,
        "255/70R22.5 140/137 M AL26": 20,
        "255/70R22.5 140/137M AL10": 20,
        "265/70R17.5 140/138M AH35": 10,
        "265/70R17.5 140/138M AH35": 10,
        "265/70R17.5 140/138M DH35": 10,
        "265/70R17.5 140/138M DH35": 10,
        "265/70R19.5 140/138M AH35": 15,
        "265/70R19.5 140/138M AU03": 15,
        "265/70R19.5 140/138M DH35": 15,
        "265/70R19.5 143/141J TH22": 15,
        "265/70R19.5 143/141J TL10+": 15,
        "275/70R22.5 148/145M AH31": 20,
        "275/70R22.5 148/145M AL10": 20,
        "275/70R22.5 148/145M DH31": 20,
        "275/70R22.5 150/145J AW02": 20,
        "275/70R22.5 150/145J DW07": 20,
        "275/70R22.5 150/147K TL10": 20,
        "275/70R22.5 150/148J AU04+": 20,
        "275/70R22.5 150/148J AU04+": 20,
        "275/70R22.5 152/148J TH31": 20,
        "275/80R22.5 149/146J AU03": 20,
        "275/80R22.5 149/146J AU04": 20,
        "275/80R22.5 149/146L AH22": 20,
        "275/80R22.5 149/146L M06": 20,
        "285/70R19.5 146/144M AH35": 15,
        "285/70R19.5 146/144M AH35": 15,
        "285/70R19.5 146/144M DH35": 15,
        "285/70R19.5 146/144M DH35": 15,
        "285/70R19.5 150/148J TH22": 15,
        "285/70R19.5 150/148J TH22": 15,
        "295/55R22.5 147/145K DL10+": 20,
        "295/60R22.5 150/147K DH31": 20,
        "295/60R22.5 150/147K TH31": 20,
        "295/60R22.5 150/147L AL10+": 20,
        "295/60R22.5 150/147L DL20W": 20,
        "295/80R22.5 152/148J AU04": 20,
        "295/80R22.5 152/148J AU04": 20,
        "295/80R22.5 152/148L DW06": 20,
        "295/80R22.5 152/148L DW07": 20,
        "295/80R22.5 152/148M AL10": 20,
        "295/80R22.5 152/148M DH05": 20,
        "295/80R22.5 152/148M DH31": 20,
        "295/80R22.5 152/148M DH31": 20,
        "295/80R22.5 152/148M DL10": 20,
        "295/80R22.5 152/148M DL10": 20,
        "295/80R22.5 154/149M AH31": 20,
        "295/80R22.5 154/149M AH31": 20,
        "295/80R22.5 154/149M AL22": 20,
        "295/80R22.5 154/149M AL22": 20,
        "295/80R22.5 154/149M AW02": 20,
        "295/80R22.5 154/149M DL22": 20,
        "305/70R19.5 148/145M AH35": 15,
        "305/70R19.5 148/145M DH35": 15,
        "305/70R22.5 152/148L AL10": 20,
        "305/70R22.5 152/150L AL26": 20,
        "315/60R22.5 152/148L DH31": 20,
        "315/60R22.5 152/148L DL10+": 20,
        "315/60R22.5 152/148L DL20W": 20,
        "315/60R22.5 154/148J AU04+": 20,
        "315/60R22.5 154/148L AH31": 20,
        "315/60R22.5 154/148L AL10+": 20,
        "315/60R22.5 154/148L AL10+": 20,
        "315/70R22.5 154/150L AW02": 20,
        "315/70R22.5 154/150L DH31": 20,
        "315/70R22.5 154/150L DL10+": 20,
        "315/70R22.5 154/150L DL20W": 20,
        "315/70R22.5 154/150L DW06": 20,
        "315/70R22.5 154/150L DW06": 20,
        "315/70R22.5 154/150L DW07": 20,
        "315/70R22.5 154/150L DW07": 20,
        "315/70R22.5 156/150L AH31": 20,
        "315/70R22.5 156/150L AL10+": 20,
        "315/80R22.5 154/150M DH05": 20,
        "315/80R22.5 156/150L AH31": 20,
        "315/80R22.5 156/150L AL10+": 20,
        "315/80R22.5 156/150L AL22": 20,
        "315/80R22.5 156/150L AW02": 20,
        "315/80R22.5 156/150L DH31": 20,
        "315/80R22.5 156/150L DH31": 20,
        "315/80R22.5 156/150L DL10+": 20,
        "315/80R22.5 156/150L DW07": 20,
        "315/80R22.5 156/150L DW07": 20,
        "355/50R22.5 156K AH31": 20,
        "355/50R22.5 156L AL10+": 20,
        "385/55R19.5 156J TH31": 15,
        "385/55R22.5 160K AH31": 20,
        "385/55R22.5 160K AH31": 20,
        "385/55R22.5 160K AL20": 20,
        "385/55R22.5 160K AW02": 20,
        "385/55R22.5 160K TH22": 20,
        "385/55R22.5 160K TH22": 20,
        "385/55R22.5 160K TH31": 20,
        "385/55R22.5 160K TL20": 20,
        "385/55R22.5 160K TL20": 20,
        "385/55R22.5 160K TW01": 20,
        "385/65R22.5 154/149M TL10": 20,
        "385/65R22.5 160K AH31": 20,
        "385/65R22.5 160K AL10+": 20,
        "385/65R22.5 160K AL10+": 20,
        "385/65R22.5 160K AW02+": 20,
        "385/65R22.5 160K TH22": 20,
        "385/65R22.5 160K TH31": 20,
        "385/65R22.5 160K TL20": 20,
        "385/65R22.5 160K TW01": 20,
        "385/65R22.5 164K AH31": 20,
        "385/65R22.5 164K TH31": 20,
        "385/65R22.5 164K TH31": 20,
        "425/65R22.5 165K TH22": 20,
        "435/50R19.5 160J TH31": 20,
        "435/50R19.5 160J TL20": 20,
        "445/45R19.5 154/150L TL10": 20,
        "445/45R19.5 160J TH31": 20,
        "445/45R19.5 160J TW01": 20,
        "445/45R19.5 160K TL20": 20,
        "445/65R22.5 152/148L TL10": 20,
        "445/65R22.5 169K TH31": 20,
        "455/40R22.5 160J TL10+": 20,
        "455/45R22.5 160J TH31": 20,
        "8.5R17.5 121/120L AH35": 10,
        "8.5R17.5 121/120L DH35": 10,
        "8.5R17.5 121/120L DH35": 10,
        "825R15 143/141G TH06": 10,
        "9.5R17.5 129/127L AH35": 10,
        "9.5R17.5 129/127L DH35": 10,
        "9.5R17.5 143/141J TH22": 10,
      }
    };
  }, 
  computed: {
    total(){
      let total = 0;
      for (let i = 0; i < this.result.prix_produits.length; i++) {
        const pp = this.result.prix_produits[i];
        total += (pp.quantity*this.refs[pp.produit]);
      }
      return total;
    },
    buying_date(){
     return dayjs(this.result.buying_date).format('DD/MM/YYYY');
    }
  }
};
</script>

						